import api from "./api.js";
var destination_term = localStorage.getItem("destination_term") || "Destination";
var warehouse_term = localStorage.getItem("warehouse_term") || "Origin";

export default {
  data: () => ({
    tenantType: sessionStorage.getItem("tenant_type") ?? null,
    valid: false,
    dialog: false,
    selectedObject: { id: -1 },
    loading: true,
    deleteDialog: false,
    search: "",
    tabs: null,
    selectedItem: 1,
    snackbar: false,
    text: "",
    id: null,
    info: [],
    carouselDialog: false,
    destination_term: destination_term,
    carouselImages: [],
    carouselTitle: '',
    textProps: {
      "v-model": "msg",
    },
    dateProps: {
      color: "primary",
    },
    timeProps: {
      format: "24hr",
      color: "primary",

      landscape: true,
    },
    showDestinationModal: false,
    destinations: [], // Destination details for the selected trip
    NormalRules: [(v) => !!v || "Field is required"],
    types: [],
    selected_start_date: null,
    selected_driver: null,
    selected_warehouse: null,
    selected_type: null,
    start_date: null,
    data: [],
    pictures: [],
    type: null,
    myCurrentData: [],
    warehouses: [],
    drivers: [],
    infoDialog: false,
    fdate: null,
    tdate: null,
    fmenu: false,
    tmenu: false,
    show1: false,
    json_fields: {
      Id: "id",
      "Driver Name": "driver.fullname",
      Warehouse: "warehouse.title",
      Type: "type0.title",
      Status: "flag",
      "Start At": "start_date",
      "Picked On": "started_date",
      "Delivered On": "ended_date",
      "Nb of Accidents": "accidents.length",
      "Nb of Fines": "fines.length",
    },
    headers: [
      {
        text: "Driver Name",
        value: "driver.fullname",
        align: "center",
      },
      {
        text: warehouse_term,
        value: "warehouse.title",
        align: "center",
      },
      {
        text: "Type",
        value: "type0.title",
        align: "center",
      },
      {
        text: "Status",
        value: "flag",
        align: "center",
      },
      {
        text: "Created On",
        value: "created_date",
        align: "center",
      },
      {
        text: "Start Date",
        value: "start_date",
        align: "center",
      },
      {
        text: "Picked On",
        value: "started_date",
        align: "center",
      },
      {
        text: "Delivered On",
        value: "ended_date",
        align: "center",
      },
      {
        text: "Accident(s)",
        value: "accidents",
        align: "center",
      },
      {
        text: "Fine(s)",
        value: "fines",
        align: "center",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
  }),
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.data = [];
      api
        .gettrips(
          this.$route.params.fdate,
          this.$route.params.tdate,
          this.$route.params.driver_id
        )
        .then((res) => {
          this.loading = false;
          if (res.data.responseCode === 1) {
            res.data.data.forEach((element) => {
              element.driver =
                element.driver != null
                  ? {
                      fullname:
                        element.driver.fname + " " + element.driver.lname,
                      id: element.driver.id,
                    }
                  : { fullname: "N" + "/" + "A", id: -1 };
              element.warehouse = {
                title: element.warehouse.title,
                id: element.warehouse.id,
              };
              element.flag =
                element.flag == 0
                  ? this.tenantType === "namat"
                    ? "Assigned"
                    : "Not Picked"
                  : element.ended_date
                  ? this.tenantType === "namat"
                    ? "Done"
                    : "Delivered"
                  : this.tenantType === "namat"
                  ? "In progress"
                  : "Picked";

              this.data.push(element);
            });
          }
        })
        .catch((error) => {
          this.text = error;
          this.snackbar = true;
          this.loading = false;
          this.errorDialog = true;
        });
    },
    getFiltered(e) {
      this.myCurrentData = e;
      //output the filtered items
    },
    back() {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
    },
    show(item) {
      this.pictures = [];
      item.pictures.forEach((element) => {
        switch (this.type) {
          case 0:
            this.pictures.push({
              src: this.$imgurl + "fines/" + element.title,
            });
            break;
          case 1:
            this.pictures.push({
              src: this.$imgurl + "accidents/" + element.title,
            });
            break;
          default:
            break;
        }
      });
      this.$nextTick(() => {
        this.tabs = "tab-" + 2;
      });
    },
    focus(type, info) {
      this.$nextTick(() => {
        this.tabs = "tab-" + 1;
      });
      this.info = [];
      switch (type) {
        case 0: //Fines
          this.type = type;
          this.info = info;
          break;
        case 1: // Accidents
          this.type = type;
          this.info = info;
          break;
        default:
          break;
      }
      this.infoDialog = true;
    },
    convertDate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();
      var hr = date.getHours();
      var mn = date.getMinutes();
      if (dt < 10) {
        dt = "0" + dt;
      }
      if (month < 10) {
        month = "0" + month;
      }
      if (hr < 10) {
        hr = "0" + hr;
      }
      if (mn < 10) {
        mn = "0" + mn;
      }
      var fulldate =
        year + "-" + month + "-" + dt + " " + hr + ":" + mn + ":00";
      return fulldate;
    },
    showModal(destinations) {
      // Set destinations without filtering
      this.destinations = destinations;
      this.showDestinationModal = true;
    },
    closeModal() {
      this.showDestinationModal = false;
      this.destinations = [];
    },
    openCarousel(images, title) {
      console.log(images);
      this.carouselImages = images;
      this.carouselTitle = title;
      this.carouselDialog = true;
    },

    getApprovalStatusText(status) {
      switch (status) {
        case "accepted":
          return "Approved";
        case "rejected":
          return "Rejected";
        case "awaiting_submission":
          return "Awaiting Submission";
        default:
          return "Unknown Status";
      }
    },

    getApprovalStatusColor(status) {
      switch (status) {
        case "accepted":
          return "green";
        case "rejected":
          return "red";
        case "awaiting_submission":
          return "orange";
        default:
          return "black";
      }
    },
  },
  computed: {
    Column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 4;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
  },
};
