import api from './api.js';

var trip_term = localStorage.getItem("trip_term") || "Trip";
export default {
    data: () => ({
        tenantType: (sessionStorage.getItem("tenant_type") ?? null),
        valid: false,
        dialog: false,
        selectedObject: { id: -1 },
        loading: true,
        deleteDialog: false,
        title: null,
        time_stop: null,
        clicked: false,
        text: '',
        snackbar: false,
        search: '',
        id: null,
        trip_term: trip_term,
        myCurrentData: [],
        json_fields: {
            'Id': 'id',
            'Name': 'title',
        },

        NormalRules: [
            v => !!v || 'Field is required',
            

        ],
        NumberRules: [
            v => v === null || v === undefined || v === '' || v >= 0 || 'Please enter a non-negative number',
          ],
          
        data: [],
        headers: [
            {
                text: 'Name',
                align: 'center',
                value: 'title'
            },


            {
                text: 'Actions',
                value: 'actions',
                align: 'center',
            },
        ],

    }),
    mounted() {
        window.addEventListener("keyup", this.handleKeyPress);
        api.gettypes().then((res) => {
            this.loading = false;
            if (res.data.responseCode === 1) {
                this.data = res.data.data;
            }
        }).catch((error) => {
            this.error = error;
            this.loading = false;
            this.errorDialog = true;
        });
    },
    beforeDestroy(){
        window.removeEventListener("keyup", this.handleKeyPress);
    },
    methods: {
        handleKeyPress(event) {
            if (event.key === "Enter") {
              if(this.dialog) this.save();
              if(this.deleteDialog) this.agreeOnDelete();
            }
            
          },
        getFiltered(e) {
            this.myCurrentData = e;
            //output the filtered items
        },
        onDelete(item) {
            this.selectedObject = item;
            this.deleteDialog = true;
        },
        showDialog(item) {
            this.valid = true;


            if (item == null) {
                this.selectedObject = { id: -1 };
                this.title = null;
                this.time_stop = null;

                this.id = null;
            } else {
                this.selectedObject = item;
                this.title = item.title;
                this.time_stop = item.time_stop;

                this.id = item.id;
            }
            this.dialog = true;


        },
        agreeOnDelete() {
            this.clicked = true;

            this.loading = true;
            api.archive(this.selectedObject.id).then((res) => {
                this.loading = false;
                if (res.data.responseCode === 1) {
                    this.deleteDialog = false;
                    this.clicked = false;
                    var index = this.data.indexOf(this.selectedObject);
                    this.$delete(this.data, index);
                    this.text = this.trip_term + ' type deleted succesfully.';
                    this.snackbar = true;
                } else {

                    this.text = res.data.responseMessage;
                    this.snackbar = true;

                }
            }).catch((error) => {
                this.loading = false;
                console.log(error);
                this.errorDialog = true;
            });
        },
        save() {
            this.clicked = true;
            if (!this.$refs.form.validate()) {
                this.clicked = false;
                return;
            }
            this.loading = true;
            this.time_stop = this.time_stop && typeof this.time_stop !== Number ? parseInt(this.time_stop) : null;
            if (this.selectedObject.id != -1) {
                api.save(this.id, this.title, this.time_stop)
                    .then((res) => {
                        this.loading = false;
                        this.dialog = false;
                        this.clicked = false;
                        if (res.data.responseCode === 1) {
                            var index = this.data.indexOf(this.selectedObject);
                            this.data[index].title = this.title;
                            this.data[index].time_stop = this.time_stop;


                            this.text = this.trip_term + ' type updated successfully.';
                            this.snackbar = true;
                        } else {
                            this.text = res.data.responseMessage;
                            this.snackbar = true;
                        }
                    }).catch((error) => {
                        this.text = error;
                        this.snackbar = true;
                        this.loading = false;
                        this.dialog = false;
                        this.clicked = false;
                    });
            } else {
                api.add(this.title, this.time_stop)
                    .then((res) => {
                        this.loading = false;
                        this.dialog = false;
                        this.clicked = false;
                        if (res.data.responseCode === 1) {
                            this.data.push({
                                id: res.data.data.id,
                                title: this.title,
                                time_stop: this.time_stop

                            });
                            this.text = this.trip_term + ' type added successfully.';
                            this.snackbar = true;

                        } else {
                            this.text = res.data.responseMessage;
                            this.snackbar = true;

                        }
                    }).catch((error) => {
                        this.text = error;
                        this.snackbar = true;
                        this.loading = false;
                        this.dialog = false;
                        this.clicked = false;
                    });
            }
        },

    },
}
