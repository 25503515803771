import api from "./api.js";

var driver_term = localStorage.getItem("driver_term") || "Driver";


export default {
  data: () => ({
    tenantType: (sessionStorage.getItem("tenant_type") ?? null),
    valid: true,
    text: "",
    snackbar: false,
    clicked_body: false,
    clicked_voice: false,
    stext: "",
    title: null,
    selected_driver: null,
    driversData: [{ fullname: "ALL", id: -1 }],
    Rules: [(v) => !!v || "Field is required"],
    disabled_body: false,
    mediaRecorder: false,
    mediaChunks: [],
    mediaState: "wait",
    audioPlayer: null,
    audioState: "initial",
    audioBlob: null,
    message_type: "Text",
    dialog: false,
    sendLoading: false,
    driver_term: driver_term,
    selectedObject: {
      title: "",
      body: "",
      date_created: "",
      seen_at: "",
      media_data: { transcribed: "", output_text: "" },
    },
    language: "Arabic",

    imgUrl: process.env.VUE_APP_IMG_URL,
    ddata: [],
    headers: [
      {
        text: "Title",
        align: "left",
        value: "title",
      },
      {
        text: driver_term + " email",
        align: "left",
        value: "driver_email",
      },
      {
        text: "Time sent",
        align: "left",
        value: "date_created",
      },
      {
        text: "Time seen",
        align: "left",
        value: "seen_at",
      },
      {
        text: "Approved",
        align: "left",
        value: "feedback",
      },
      {
        text: "Actions",
        value: "actions",
        align: "center",
      },
    ],
    notificationPlayer: null,
  }),

  methods: {
    SendNotification() {
      this.clicked_body = true;
      if (!this.$refs.form.validate()) {
        this.clicked_body = false;
        return;
      } else {
        this.sendLoading = true;

        api
          .send(this.title, this.text, this.selected_driver.id)
          .then((res) => {
            if (res.data.responseCode === 1) {
              this.snackbar = true;
              this.stext = "Notification has been sent Succesfully.";
              this.clicked_body = false;
              this.$refs.form.reset();
            } else {
              this.snackbar = true;
              this.stext = driver_term + " has no registered tokens.";
              this.clicked_body = false;
            }
            this.title = null;
            this.text = null;
            this.selected_driver = null;
          })
          .catch((error) => {
            this.stext = error;
            this.snackbar = true;
            this.clicked_body = false;
          })
          .finally(()=>{
            this.sendLoading = false;
          });
      }
    },

    async GetNotifications() {
      await api.getNotifications().then((res) => {
        if (res.data.responseCode == 1) {
          this.ddata = res.data.data.map((item) => {
            item.driver_email =
              this.driversData.filter((d) => d.id === item.driver_id)[0]
                ?.email ?? "";

            item.seen_at = item.seen_at
              ? this.convertUTCDateToLocalDate(item.seen_at)
              : "Not Seen";
            item.date_created = this.convertUTCDateToLocalDate(
              item.date_created
            );
            item.title = item.title ? item.title : "Audio";
            item.type = item.is_text ? "text" : "voice";
            return item;
          }).sort((x, y) => {
            return new Date(y.date_created) - new Date(x.date_created);
          });
        }
      });
    },

    PlayVoiceMessage(media) {
      const url = media;
      this.notificationPlayer = document.createElement("audio");
      this.notificationPlayer.src = url;

      this.notificationPlayer.addEventListener("canplaythrough", () => {
        console.log("Audio can play through");
        this.notificationPlayer
          .play()
          .then(() => {
            console.log("Audio is playing");
          })
          .catch((error) => {
            console.error("Error playing audio:", error);
          });
      });

      this.notificationPlayer.addEventListener("error", (error) => {
        console.error("Error loading audio:", error);
      });
    },

    selectLanguage(language) {
      this.language = language;
    },

    selectDriver(driver) {
      this.selected_driver = driver;
      this.language = driver.language;
      console.log(driver);
    },

    SendAudio() {
      if (this.audioState === "available") {
        this.sendLoading = true;
        api
          .sendAudio(this.selected_driver.id, this.audioBlob, this.language)
          .then((res) => {
            // TODO: driver id is not selected for voice messages - to this later
            if (res.data.responseCode === 1) {
              this.snackbar = true;
              this.stext = "Voice message has been sent Succesfully.";
              this.clicked_voice = false;
              this.$refs.form.reset();
              this.GetNotifications();
            } else {
              this.snackbar = true;
              this.stext = res.data.responseMessage;
              this.clicked_voice = false;
            }
            this.title = null;
            this.text = null;
            this.selected_driver = null;
          })
          .catch((error) => {
            this.stext = error;
            this.snackbar = true;
            this.clicked_voice = false;
          }).finally(()=>{
            this.sendLoading = false;
          });
      }
    },

    SetUpMedia(callback) {
      navigator.mediaDevices
        .getUserMedia({
          audio: true,
        })
        .then((stream) => {
          this.mediaRecorder = new MediaRecorder(stream);

          this.mediaRecorder.ondataavailable = (e) => {
            this.mediaChunks.push(e.data);
          };

          this.mediaRecorder.onstop = () => {
            const blob = new Blob(this.mediaChunks);
            this.mediaChunks = [];
            const audioURL = window.URL.createObjectURL(blob);
            this.audioPlayer.src = audioURL;
            this.audioState = "available";

            // prepare base64 for server
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
              this.audioBlob = reader.result;

              // clean up
              this.mediaRecorder = null;
              stream.getTracks().forEach((t) => t.stop());
            };
          };

          callback();
        })
        .catch((error) => {
          console.log("Following error has occured: ", error);
        });
    },

    RecordMessage() {
      this.SetUpMedia(() => {
        this.mediaState = "recording";
        this.mediaRecorder.start();
        this.disabled_body = true;
        this.clicked_body = true;
        this.text = "";
      });
    },

    StopRecording() {
      this.mediaState = "wait";
      this.mediaRecorder.stop();
    },

    changeMessageType(type) {
      this.message_type = type;
    },

    ClearRecording() {
      this.mediaState = "wait";
      this.mediaRecorder = false;
      this.disabled_body = false;
      this.clicked_body = false;
      this.audioState = "initial";
    },

    StartPlaying() {
      if (this.audioState === "available") {
        this.audioPlayer.play();
      }
    },

    convertUTCDateToLocalDate(utcDate) {
      if (utcDate) {
        const date = new Date(utcDate);
        var newDate = new Date(
          date.getTime() + date.getTimezoneOffset() * 60 * 1000
        );

        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();

        newDate.setHours(hours - offset);

        return newDate.toLocaleString();
      }

      return "";
    },

    showDialog(item) {
      this.valid = true;
      if (item == null) {
        this.selectedObject = { id: -1 };
      } else {
        let data = {};
        console.log(item);
        if (typeof item.media_data === 'string') {
          try {
            
            data = JSON.parse(item.media_data);
          } catch (error) {
            console.error("Error on parsing the media data:", error);
            data = {};
          }
        } else if (typeof item.media_data === 'object' && item.media_data !== null) {
          data = item.media_data; 
        }
    
        this.selectedObject = {
          title: item.title,
          type: item.is_text ? "text" : "voice",
          body: item.body,
          date_created: this.convertUTCDateToLocalDate(item.date_created) ?? "",
          seen_at: this.convertUTCDateToLocalDate(item.seen_at) ?? "Not Seen",
          media_data: {
            transcribed: data?.transcribed ?? "",
            output: data?.output_text ?? "",
            media_original: item.media_original ?? "",
          },
        };
      }
    
      this.dialog = true;
    },
    
  },

  async mounted() {
    await api
      .getdrivers()
      .then((res) => {
        if (res.data.responseCode === 1) {
          res.data.data.forEach((element) => {
            element.fullname = element.fname + " " + element.lname;
            this.driversData.push({
              fullname: element.fullname,
              id: element.id,
              language: element.language,
              email: element.email,
            });
          });
        }
      })
      .catch((error) => {
        this.text = error;
        this.snackbar = true;
        this.loading = false;
        this.errorDialog = true;
      });

    if (
      this.$route.params.driverName != null &&
      this.$route.params.driver_id != null
    ) {
      console.log(this.$route.params.driverName);
      console.log(this.$route.params.driver_id);
      this.selected_driver = {
        fullname: this.$route.params.driverName,
        id: this.$route.params.driver_id,
      };
    }

    this.audioPlayer = document.createElement("audio");
    this.audioPlayer.controls = true;

    await this.GetNotifications();

    this.loading = false;
    this.notificationPlayer = document.createElement("audio");
    this.notificationPlayer.controls = true;

    setInterval(() => {
      this.GetNotifications();
    }, 60000);
  },
  computed: {
    column() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs": {
          return 12;
        }

        case "sm": {
          return 12;
        }

        case "md": {
          return 4;
        }
        case "lg": {
          return 4;
        }
        case "xl": {
          return 4;
        }
      }
    },
    isRecording() {
      return this.mediaState === "recording";
    },
    isNotRecording() {
      return this.mediaState !== "recording";
    },
    isAudioNotAvailable() {
      return this.audioState !== "available";
    },
    notificationBody() {
      return this.text !== "" && this.text !== null;
    },
  },
};
