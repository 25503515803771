export default {
  settings: {},
  defaultSettings: {},

  spaceUnits: [],
  liquidUnits: [],
  currencies: [],

  get defaultTenantSettings() {
    return Object.keys(this.defaultSettings);
  },

  setSettings(settings) {
    settings.forEach(({ key, value }) => {
      if (this.defaultTenantSettings.includes(key)) {
        this.settings[key] = value;
        localStorage.setItem(key, value);
      }
    });
  },

  setDefaultSettings(defaultSettings) {
    this.defaultSettings = defaultSettings;
  },

  initializeSettingsFromStorage() {
    this.defaultTenantSettings.forEach((key) => {
      const storedValue = localStorage.getItem(key);
      if (storedValue !== null) {
        this.settings[key] = storedValue;
      }
    });
  },

  setSpaceUnits(units) {
    this.spaceUnits = units;
  },

  setLiquidUnits(units) {
    this.liquidUnits = units;
  },

  setCurrencies(currencies) {
    this.currencies = currencies;
  },

  setTerm(term, field) {
    if (!this.defaultTenantSettings.includes(field)) return false;
    localStorage.setItem(field, term);
    this.settings[field] = term;
    return true;
  },

  getFromStorage(key) {
    const item = localStorage.getItem(key);
    try {
      return JSON.parse(item);
    } catch (error) {
      console.error(`Error parsing JSON from localStorage for key "${key}":`, error);
      return null;
    }
  },

  getCurrencyNames() {
    this.currencies = this.getFromStorage("currencies") || [];
    return this.currencies.map((currency) => currency.name);
  },

  getLiquidUnits() {
    this.liquidUnits = this.getFromStorage("volumeUnits") || [];
    return this.liquidUnits.map((unit) => unit.name);
  },

  getSpaceUnits() {
    this.spaceUnits = this.getFromStorage("spaceUnits") || [];
    return this.spaceUnits.map((unit) => unit.name);
  },

  getDefaultSettings() {
    const defaultSettingsArray = this.getFromStorage("defaultSettings") || [];
    this.defaultSettings = defaultSettingsArray.reduce((obj, { key, value }) => {
      obj[key] = value;
      return obj;
    }, {});
    return this.defaultSettings;
  },

  fromNameToSymbol(name, type = "currency") {
    let collection;

    switch (type) {
      case "currency":
        collection = this.currencies;
        break;
      case "spaceUnit":
        collection = this.spaceUnits;
        break;
      case "liquidUnit":
        collection = this.liquidUnits;
        break;
      default:
        console.error(`Invalid type "${type}" provided.`);
        return null;
    }

    const selectedItem = collection.find((item) => item.name === name);

    if (selectedItem) {
      return selectedItem.symbol;
    } else {
      return null;
    }
  },
};
