import axios from "@/plugins/http.service.js";

export default {
 
  getManagerApproval(status = "", page = 1, pageSize = 10, driver_name = "") {
  return axios.get(`v1/trips/destinations/approvals/manager?manager_approval_status=${status}&page=${page}&page_size=${pageSize}&driver_name=${driver_name}`);
  },
  getClientApproval( page = 1, pageSize = 10, driver_name = "") {
    return axios.get(`v1/trips/destinations/approvals/client?page=${page}&page_size=${pageSize}&driver_name=${driver_name}`);
  },
  updateManagerRequest(task_id, status) {
    return axios.patch("trip/update-approval-status", {
      task_id,
      status,
    });
  },

  getApprovalStatusSummary() {
    return axios.get('v1/trips/destinations/approvals/manager/summary');
  },
};
