<template>
    <Bar :data="chartData" :options="chartOptions" />
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs';
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
  var trip_term = localStorage.getItem("trip_term") || "Trip";

  export default {
    name: 'BarChart',
    components: { Bar },
    props: {
        chartData: {
        type: Object,
        required: true,
        },
        chartOptions: {
        type: Object,
        required: false,
        default() {
            return {
            responsive: true,
            scales: {
                x: {
                ticks: {
                    font: {
                    size: 11,
                    },
                    maxRotation: 45,
                    minRotation: 45,
                },
                },
                y: {
                beginAtZero: true,
                ticks: {
                    font: {
                    size: 14,
                    },
                },
                },
            },
            plugins: {
                legend: {
                labels: {
                    font: {
                    size: 21,
                    },
                },
                },
                tooltip: {
                    bodyFont: {
                        size: 14,
                    },
                    callbacks: {
                        label: (tooltipItem) => `${tooltipItem.raw} ${trip_term}s`,
                    },
                },
            },
            };
        },
        },
    },
    };

  </script>
  