import axios from "@/plugins/http.service.js";

export default {
 
  getDriversWithMostTrips(fdate, ldate) {
    return axios.get(`analytics/get-drivers-with-most-trips?fdate=${fdate}&ldate=${ldate}`);
    },
  getDriversWithMostWorkingHours(fdate, ldate) {
      return axios.get(`analytics/get-drivers-with-most-working-hours?fdate=${fdate}&ldate=${ldate}`);
    },
  getDriversWithFewestIncidents(fdate, ldate) {
      return axios.get(`analytics/get-drivers-with-fewest-incidents?fdate=${fdate}&ldate=${ldate}`);
  },

  getDriversWithMostApprovalsAccepted(fdate, ldate) {
      return axios.get(`analytics/get-drivers-with-most-approval-accepted?fdate=${fdate}&ldate=${ldate}`);
    },

  getDriversWithMostExceptions(fdate, ldate) {
    return axios.get(`analytics/get-drivers-with-most-exceptions?fdate=${fdate}&ldate=${ldate}`);
  },
};
