<template>
  <div>
    <CustomLineChart :data="chartData" :options="chartOptions" />
  </div>
</template>
  
  <script>
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';
  import { Line as CustomLineChart } from 'vue-chartjs';
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );
  
  export default {
    name: 'LineChart',
    components: {
      CustomLineChart,
    },
    props: {
      chartData: {
        type: Object,
        required: true,
      },
      chartOptions: {
        type: Object,
        default: () => ({
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              labels: {
                font: {
                  size: 21,
                },
              },
            },
          },
          
        }),
      },
    },
  };
  </script>
  
  <style scoped>
  div {
    height: 400px;
  }
  </style>
  