import axios from "@/plugins/http.service.js";

export default {
  gettypes() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.post(`type/get-types?tenant_id=${tenant_id}`);
  },
  gettrips(fdate, tdate, page, pageSize) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      fdate: fdate,
      tdate: tdate,
      tenant_id,
    };
    return axios.post(`trip/get-all-trips?page=${page}&page_size=${pageSize}`, data);
  },
  add(driver_id, warehouse_id, type, start_date, note, trip_destinations, departure_time_range) {
    var data = {
      driver_id: driver_id,
      warehouse_id: warehouse_id,
      type: type,
      start_date: start_date,
      note,
      destinations: trip_destinations,
      departure_time_range: departure_time_range
    };
    return axios.post("trip/add-trip", data);
  },
  save(id, driver_id, warehouse_id, type, start_date, note, trip_destinations, departure_time_range) {
    var data = {
      id: id,
      driver_id: driver_id,
      warehouse_id: warehouse_id,
      type: type,
      start_date: start_date,
      note,
      destinations: trip_destinations,
      departure_time_range: departure_time_range
    };
    return axios.post("trip/save", data);
  },
  getdrivers() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.get(`driver/get-drivers?tenant_id=${tenant_id}`);
  },
  archive(id) {
    var data = {
      id: id,
    };
    return axios.post("trip/archive", data);
  },

  getwarehouses() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    return axios.post(`warehouse/get-warehouse?tenant_id=${tenant_id}`);
  },

  add_driver(fname, lname, email, password, bus_id, hourly_rate, language) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      fname: fname,
      lname: lname,
      email: email,
      password: password,
      bus_id: bus_id,
      hourly_rate: hourly_rate,
      tenant_id,
      language,
    };
    return axios.post("driver/add-driver", data);
  },

  getbuses() {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      type: 1,
    };
    return axios.post(`bus/get-buses?tenant_id=${tenant_id}`, data);
  },

  getlanguages(){
    
    return axios.post(`language/get-languages?`);
  },

  addwarehouse(title, lat, lon) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      title: title,
      tenant_id,
      lat,
      lon,
    };

    return axios.post("warehouse/add", data);
  },

  addbus(reference) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      reference: reference,
      tenant_id,
    };

    return axios.post("bus/add", data);
  },

  addtype(title, time_stop) {
    const tenant_id = sessionStorage.getItem("cooriroo_tenant_id");

    var data = {
      title: title,
      time_stop: time_stop,
      tenant_id,

    };

    return axios.post("type/add", data);
  },
};
