<template>
    <div>
      <CustomPieChart :data="processedChartData" :options="chartOptions" />
    </div>
  </template>
  
  <script>
  import { Pie as CustomPieChart } from "vue-chartjs";
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
  } from "chart.js";
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    LinearScale,
    ArcElement,
    CategoryScale
  );
  
  export default {
    name: "PieChart",
    components: {
      CustomPieChart,
    },
    props: {
      chartData: {
        type: Object,
        required: true,
      },
      chartOptions: {
        type: Object,
        default: () => ({
            
          responsive: true,
          plugins: {
            tooltip: {
              callbacks: {
                label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw} hours`,
              },
            },
          },
        }),
      },
    },
    computed: {
      processedChartData() {
        return {
          ...this.chartData,
          datasets: this.chartData.datasets.map((dataset) => ({
            ...dataset,
            backgroundColor: dataset.data.map(() => this.generateRandomColor()),
          })),
        };
      },
    },
    methods: {
      generateRandomColor() {
        const randomValue = () => Math.floor(Math.random() * 256);
        return `rgba(${randomValue()}, ${randomValue()}, ${randomValue()}, 0.7)`;
      },
    },
  };
  </script>
  
  <style scoped>
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      border: none;

    }
  </style>
  